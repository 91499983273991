import client from "./client";

export const allStudentsAgency = async (id) => {
  try {
    const { data } = await client.get(`/acc_agency/allStudentsAgency/${id}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const agencyDetails = async (id) => {
  try {
    const { data } = await client.get(`/acc_agency/agencyDetails/${id}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const setPassword = async (obj) => {
  try {
    
    const { data } = await client.put("/acc_agency/setPassword", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
