import React, { createContext, useEffect, useState } from "react";
import { getPaymentInfo } from "../api/payment";
import { allStudentsInfoByAccounter } from "../api/accounter";
import { agencyDetails } from "../api/agency";
import { decryptRole } from "../Authentication/EncryptionUtils";
import toast from "react-hot-toast";
import { getRefundPaymentInfo, studentUpdate } from "../api/student";

const StudentContext = createContext();

const StudentProvider = ({ children }) => {
  const [students, setStudents] = useState([]);

  const [agency, setAgency] = useState([]);
  const [inEditStudent, setInEditStudent] = useState({});
  const [refundPayment, setRefundPayment] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState({
    applicationTransactions: [],
    tutionTransactions: [],
  });

  const [stage, setStage] = useState(0);

  const getstudents = async () => {
    const data = await allStudentsInfoByAccounter();
    setStudents(data);
  };

  const getRefundPayment = async () => {
    try {
      const data = await getRefundPaymentInfo(inEditStudent.id);

      if (data.success) {
        setRefundPayment({
          transactions: data.refundpayment,
        });
      }
    } catch (error) {
      console.error("Error fetching payment info:", error);
    }
  };

  const fetchPaymentInfo = async () => {
    try {
      const data = await getPaymentInfo(inEditStudent.id);
      if (data.success) {
        let appTransactions = [];
        let tutTransactions = [];
        let commission = [];
        appTransactions = data.paymentInfo.filter(
          (item) => item.type === "Application_Fee"
        );
        tutTransactions = data.paymentInfo.filter(
          (item) => item.type === "Tution_Fee"
        );
        commission = data.paymentInfo.filter(
          (item) => item.type === "Commission"
        );
        setPaymentInfo({
          applicationTransactions: appTransactions,
          tutionTransactions: tutTransactions,
          commissionTransaction: commission,
        });
      }
    } catch (error) {
      console.error("Error fetching payment info:", error);
    }
  };
  useEffect(() => {
    if (inEditStudent.id) {
      fetchPaymentInfo();
    }
    getRefundPayment();
  }, [inEditStudent]);

  const getAgency = async (id) => {
    const data = await agencyDetails(id); // Done Works
    setAgency(data);
    return data;
  };

  const getRole = () => {
    if (localStorage.getItem("role")) {
      // return localStorage.getItem("role");
      return decryptRole(localStorage.getItem("role"));
    } else {
      return null;
    }
  };

  const [role, setRole] = useState(getRole());
  useEffect(() => {
    const roleFromStorage = getRole();
    setRole(roleFromStorage);
  }, []);

  const contextValues = {
    students,
    setStudents,
    agency,
    stage,
    setStage,
    getAgency,
    role,
    getRole,
    getstudents,
    inEditStudent,
    setInEditStudent,

    paymentInfo,
    setPaymentInfo,

    fetchPaymentInfo,

    refundPayment,
    setRefundPayment,
  };

  useEffect(() => {
    let aId = localStorage.getItem("id");
    getRole();

    getAgency(aId);
  }, []);

  return (
    <StudentContext.Provider value={contextValues}>
      {children}
    </StudentContext.Provider>
  );
};

export { StudentContext, StudentProvider };
