import React, { useEffect, useState, useContext } from "react";
import { toast, Toaster } from "react-hot-toast";
import { StudentContext } from "../../../context/StudentContext";
import CommissionRow from "./CommissionRow";
const Paymentcompo = () => {
  const [cal, setCal] = useState(0);
  const [status, setStatus] = useState("Pending");
  const studentContext = useContext(StudentContext);
  const { inEditStudent, paymentInfo, setPaymentInfo } = studentContext;
  const [formState, setFormState] = useState({});
  const addTransaction = () => {
    const calData = fetchCal();
    if (calData === 0) {
      toast.success("Tution Fee Payment Already Done!!");
      return;
    }
    const newTutionPayments = [
      ...paymentInfo.commissionTransaction,
      {
        amount: 0,
        ttCopyName: "",
        ttCopy: null,
        transactionNo: "",
        paymentStatus: "",
        id: null,
        type: "Commission",
      },
    ];
    setPaymentInfo({
      ...paymentInfo,
      commissionTransaction: newTutionPayments,
    });
  };

  const fetchCal = () => {
    let tAmount = 0;
    paymentInfo.commissionTransaction.forEach((transaction) => {
      tAmount += transaction.amount;
    });
    let calData = parseInt(inEditStudent.tutionFee) - parseInt(tAmount);

    setCal(calData);
    return calData;
  };

  const handleChangeTransaction = (transactionDetails, event, index) => {
    const { name, value, type, files } = event.target;
    const updatedTransaction = transactionDetails;
    const allTransaction = paymentInfo.commissionTransaction;
    if (type === "file") {
      const selectedFile = files[0];
      updatedTransaction["ttCopyName"] = selectedFile.name;
      updatedTransaction["ttCopy"] = selectedFile;
    } else {
      if (name === "amount") updatedTransaction[name] = parseInt(value);
      else updatedTransaction[name] = value;
    }
    allTransaction[index] = updatedTransaction;
    setPaymentInfo({
      ...paymentInfo,
      commissionTransaction: allTransaction,
    });
  };

  // const roleAC = isAdmin ? "Admin" : selectedCounselorLoop?.name;

  useEffect(() => {
    if (
      inEditStudent?.paymentStatus === "Pending" ||
      inEditStudent?.paymentStatus === "Done"
    ) {
      setStatus(inEditStudent?.paymentStatus);
    }
    if (inEditStudent?.paymentStatus === "Done") {
      setStatus("Done");
      setFormState({ ttCopyName: inEditStudent.ttCopyName });
    }
    fetchCal();
  }, []);

  return (
    <div className="container mx-auto h-full mt-8 w-full">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white p-8 rounded-lg max-h-4/5 mx-auto border-2 border-gray-300 shadow-lg transition duration-300">
        <div className="mt">
          <div className="grid lg:grid-cols-1 items-center">
            <button
              onClick={addTransaction}
              className="px-8 py-3 ml-auto bg-blue-500 hover:bg-blue-600 text-white w-fit rounded my-2"
            >
              Add
            </button>
            <div className="overflow-x-auto">
              <table className="w-full rounded-xl bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Created At
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Add ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Amount (€)
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">Bank</th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Save ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      View ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Transaction Number
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Save Transaction Info
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Payment Status
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentInfo.commissionTransaction &&
                  paymentInfo.commissionTransaction.length > 0 ? (
                    paymentInfo.commissionTransaction.map((payment, index) => (
                      <CommissionRow
                        studentId={inEditStudent.id}
                        handleChangeTransaction={handleChangeTransaction}
                        payment={payment}
                        index={index}
                        fetchCal={fetchCal}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {paymentInfo.commissionTransaction.length === 0 && (
                <div className="text-center py-4">
                  <p>No payment information available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentcompo;
