import React, { useState, useRef, useEffect, useContext } from "react";
import "tailwindcss/tailwind.css";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Space, Table, Modal, DatePicker, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import data from "../data";
import Navbar from "../Modal/Navbar";
import { decryptRole } from "../../helper/EncryptionUtils";
import { StudentContext } from "../../context/StudentContext";
import { getBdms, getStudentFeeDetails } from "../../api/accounter";
import { FeeDetailsHeader } from "../../helper/FeeDetailsHeader";

import { CSVLink } from "react-csv";
import Report from "./Report/Report";

const DynamicTable = ({ showModal, data }) => {
  const [searchValue, setSearchValue] = useState("");
  const studentContext = useContext(StudentContext);
  const {
    students,
    setStudents,
    stage,
    setStage,
    setInEditStudent,
    getstudents,
  } = studentContext;

  let navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleEditStudent = async (id) => {
    const student = students.filter((student) => student.id === id);
    setInEditStudent(student[0]);
    showModal();
  };

  const getStatusForRow = (stage, stageNumber) => {
    const header = [
      { key: 1, title: "Application Submmited" },
      { key: 2, title: "Counsellor Allocated" },
      { key: 3, title: "Interview Scheduled" },
      { key: 4, title: "Result Announced" },
      { key: 5, title: "Offer Letter sent" },
      { key: 6, title: "Payment" },
      { key: 7, title: "Approval Letter sent" },
      { key: 8, title: "Confirmed Students" },
    ];
    let matchingHeader = "";
    if (stage !== 0) {
      matchingHeader = header.find((item) => item.key === stageNumber);
    } else {
      matchingHeader = header.find((item) => item.key === stageNumber);
    }

    let status = matchingHeader ? matchingHeader.title : "Unknown Stage";
    return status;
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchValue("");
  };
  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    isButtonClick = false
  ) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (isButtonClick) {
      confirm();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const searchText = e.target.value;
            setSelectedKeys(searchText ? [searchText] : []);
            if (!searchText) {
              handleReset(clearFilters);
            } else {
              handleSearch([searchText], confirm, dataIndex);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
              setSearchText("");
              setSearchText("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Enrollment No",
      dataIndex: "enrollmentno",
      key: "enrollmentno",
      align: "center",
      width: 110,
      ...getColumnSearchProps("enrollmentno"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ""}
          />
        );
        return (
          <div
            className="bg-transparent rounded-lg -ml-2 -mr-2"
            onClick={() => {
              handleEditStudent(record?.id);
            }}
          >
            <h2 className="text-sm font-bold -mb-2 text-center transition duration-300 ease-in-out transform hover:scale-105 text-blue-500 cursor-pointer">
              {searchedColumn === "enrollmentno"
                ? highlightText
                : text?.toString().toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      width: 110,
      ...getColumnSearchProps("firstName"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "firstName"
                ? highlightText
                : text.toString().toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      align: "center",
      width: 120,
      ...getColumnSearchProps("lastName"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "lastName"
                ? highlightText
                : text.toString().toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 90,
      ...getColumnSearchProps("email"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
        return (
          <div
            className="bg-transparent rounded-lg -ml-2 -mr-2"
            onClick={() => handleEditStudent(record?.id)}
          >
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "email" ? highlightText : text}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Contact No",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
      width: 110,
      ...getColumnSearchProps("phoneNumber"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center ">
              {searchedColumn === "phoneNumber"
                ? highlightText
                : text.toString().toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Course",
      dataIndex: "diploma",
      key: "diploma",
      width: 150,
      filters: [
        { text: "BBA", value: "BBA" },
        { text: "MBA", value: "MBA" },
      ],
      onFilter: (value, record) => record.diploma.includes(value),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center ">
              {record?.diploma}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      key: "clientType",
      align: "center",
      width: 110,
      ...getColumnSearchProps("clientType"),
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "Agency Name",
      dataIndex: "agencyName",
      key: "agencyName",
      align: "center",
      width: 110,
      ...getColumnSearchProps("agencyName"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center ">
              {searchedColumn === "agencyName"
                ? highlightText
                : text?.toString()?.toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "BDM1",
      dataIndex: "bdm1",
      key: "bdm1",
      align: "center",
      width: 110,
      ...getColumnSearchProps("bdm1"),
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "BDM2",
      dataIndex: "bdm2",
      key: "bdm2",
      align: "center",
      width: 110,
      ...getColumnSearchProps("bdm2"),
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "Application Fee Paid",
      dataIndex: "Application_Fee_Paid",
      key: "Application_Fee_Paid",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "Total Tution Fees",
      dataIndex: "totalTutionFee",
      key: "totalTutionFee",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
    },

    {
      title: "Tution Fee Paid",
      dataIndex: "Tution_Fee_Paid",
      key: "Tution_Fee_Paid",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "Total Fees Paid",
      dataIndex: "totalFeeToPaid",
      key: "totalFeeToPaid",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {record?.Tution_Fee_Paid + record?.Application_Fee_Paid}
            </h2>
          </div>
        );
      },
    },

    {
      title: "Date of Payment",
      dataIndex: "maxCreatedAt",
      key: "maxCreatedAt",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
    },
    {
      title: "Last Approval Date",
      dataIndex: "maxApprovedOn",
      key: "maxApprovedOn",
      align: "center",
      width: 110,
      className: "custom-header-class font-semibold border border-slate-300",
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {record?.maxApprovedOn === "Invalid date"
                ? ""
                : record?.maxApprovedOn}
            </h2>
          </div>
        );
      },
    },
    {
      title: "Application Fees Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 150,
      filters: [
        { text: "Done", value: "Done" },
        { text: "Sent for Approval", value: "Sent for approval" },
      ],
      onFilter: (value, record) => record?.paymentStatus?.includes(value),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center ">
              {record?.paymentStatus}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Tution Fees Status",
      dataIndex: "tuitionFeesStatus",
      key: "tuitionFeesStatus",
      width: 150,
      filters: [
        { text: "Done", value: "Done" },
        { text: "Sent for Approval", value: "Sent for approval" },
      ],
      onFilter: (value, record) => record?.tuitionFeesStatus?.includes(value),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center ">
              {record?.tuitionFeesStatus}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
  ];

  useEffect(() => {
    if (
      (localStorage.getItem("role") &&
        decryptRole(localStorage.getItem("role"))) !== "Accounter"
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log(students);
  }, []);
  return (
    <Table
      dataSource={data}
      columns={columns}
      className="w-full"
      scroll={{ x: true }}
    />
  );
};
function StudentTable() {
  const studentContext = useContext(StudentContext);
  const { students, getstudents, setStage } = studentContext;

  const { stageNo } = useParams();

  const [tableData, setTableData] = useState([]);
  const [bdmReport, setBdmReport] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState({
    dataArray: [],
  });
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  // Popup requirements !
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBdmReportOpen, setIsBdmReportOpen] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleBdmReportOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleBdmReportCancel = () => {
    setIsBdmReportOpen(false);
  };

  const handleDownload = () => {
    document.getElementById("csv-link").click();
  };

  function calculateBdmWiseTotals(data) {
    let bdmWiseTotals = {};

    let othersRecords = {
      bdm: "OTHERS",
      totalApplicationFee: 0,
      applicationStudent: 0,
      applicationIncentive: 0,

      totalTutionFee: 0,
      tutionStudent: 0,
      tutionIncentive: 0,

      totalFees: 0,
    };

    data.forEach((entry) => {
      let bdm1 = entry.bdm1;
      let bdm2 = entry.bdm2;
      if (!bdm1 || !bdm2) {
        othersRecords.totalApplicationFee += parseInt(
          entry.Application_Fee_Paid
        );
        othersRecords.totalTutionFee += parseInt(entry.Tution_Fee_Paid);
        othersRecords.totalFees +=
          parseInt(entry.Application_Fee_Paid) +
          parseInt(entry.Tution_Fee_Paid);

        if (entry.Application_Fee_Paid >= 200) {
          othersRecords.applicationStudent += 1;
          othersRecords.applicationIncentive += 5000;
        }
        if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
          othersRecords.tutionStudent += 1;
          othersRecords.tutionIncentive += 10000;
        }
      }
      if (bdm1 && bdm2) {
        const totalApplicationFee = parseInt(entry.Application_Fee_Paid);
        const totalTutionFee = parseInt(entry.Tution_Fee_Paid);
        const totalFees = totalApplicationFee + totalTutionFee;

        if (bdm1 === bdm2) {
          if (!bdmWiseTotals[bdm1]) {
            bdmWiseTotals[bdm1] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }
          bdmWiseTotals[bdm1].totalApplicationFee += totalApplicationFee;
          bdmWiseTotals[bdm1].totalTutionFee += totalTutionFee;
          bdmWiseTotals[bdm1].totalFees += totalFees;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm1].applicationStudent += 1;
            bdmWiseTotals[bdm1].applicationIncentive += 5000;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm1].tutionStudent += 1;
            bdmWiseTotals[bdm1].tutionIncentive += 10000;
          }
        } else {
          if (!bdmWiseTotals[bdm1]) {
            bdmWiseTotals[bdm1] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }
          if (!bdmWiseTotals[bdm2]) {
            bdmWiseTotals[bdm2] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }

          const halftotalApplicationFeeTotalFees = totalApplicationFee / 2;
          const halftotalTutionFeeTotalFees = totalTutionFee / 2;

          const totalHalfFeessum =
            halftotalApplicationFeeTotalFees + halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm1].totalApplicationFee +=
            halftotalApplicationFeeTotalFees;
          bdmWiseTotals[bdm1].totalTutionFee += halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm1].totalFees += totalHalfFeessum;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm1].applicationStudent += 1;
            // bdmWiseTotals[bdm1].applicationIncentive += 5000;
            bdmWiseTotals[bdm1].applicationIncentive += 2500;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm1].tutionStudent += 1;
            //bdmWiseTotals[bdm1].tutionIncentive += 10000;
            bdmWiseTotals[bdm1].tutionIncentive += 5000;
          }

          bdmWiseTotals[bdm2].totalApplicationFee +=
            halftotalApplicationFeeTotalFees;
          bdmWiseTotals[bdm2].totalTutionFee += halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm2].totalFees += totalHalfFeessum;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm2].applicationStudent += 1;
            //bdmWiseTotals[bdm2].applicationIncentive += 5000;
            bdmWiseTotals[bdm2].applicationIncentive += 2500;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm2].tutionStudent += 1;
            // bdmWiseTotals[bdm2].tutionIncentive += 10000;
            bdmWiseTotals[bdm2].tutionIncentive += 5000;
          }
        }
      }
    });
    let bdmWiseTotalsArray = Object.keys(bdmWiseTotals).map((bdm) => {
      return {
        bdm,
        ...bdmWiseTotals[bdm],
      };
    });
    bdmWiseTotalsArray.push(othersRecords);
    return bdmWiseTotalsArray;
  }

  const fetchDataFromBackend = async () => {
    const bdmInfos = await getBdms();
    const feeInfo = await getStudentFeeDetails();
    let updatedStudents = [];
    students.map((student) => {
      updatedStudents.push({
        ...student,
        ...feeInfo.result[student.enrollmentno.substring(3)],
        bdm1: bdmInfos.result[student.enrollmentno.substring(3)]?.BDM1,
        bdm2: bdmInfos.result[student.enrollmentno.substring(3)]?.BDM2,
        clientType:
          bdmInfos.result[student.enrollmentno.substring(3)]?.clientType,
        agencyName:
          bdmInfos.result[student.enrollmentno.substring(3)]?.agencyName,
      });
    });
    const bdmreport = calculateBdmWiseTotals(updatedStudents);
    setBdmReport(bdmreport);

    console.log(stageNo);

    if (stageNo === "1") {
      updatedStudents = updatedStudents.filter(
        (student) => student.paymentStatus === "Sent for approval"
      );
    } else if (stageNo === "2") {
      updatedStudents = updatedStudents.filter(
        (student) => student.tuitionFeesStatus === "Sent for approval"
      );
    } else if (stageNo === "3") {
      updatedStudents = updatedStudents.filter(
        (student) => student.paymentStatus === "Done"
      );
    } else if (stageNo === "4") {
      updatedStudents = updatedStudents.filter(
        (student) => student.tuitionFeesStatus === "Done"
      );
    } else if (stageNo === "5") {
      updatedStudents = updatedStudents.filter(
        (student) => student.tuitionRefundStatus === "Send For Approval"
      );
    } else if (stageNo === "6") {
      updatedStudents = updatedStudents.filter(
        (student) => student.tuitionRefundStatus === "Approved"
      );
    } else if (stageNo === "7") {
      updatedStudents = updatedStudents;
    } else if (stageNo === "8") {
      updatedStudents = updatedStudents.filter(
        (student) => student.applicationRefundStatus === "Send For Approval"
      );
    } else if (stageNo === "9") {
      updatedStudents = updatedStudents.filter(
        (student) => student.applicationRefundStatus === "Approved"
      );
    } else if (stageNo === "10") {
      updatedStudents = updatedStudents.filter(
        (student) =>
          student.commissionStatus === "Done" ||
          student.commissionStatus === "Send For Approval"
      );
    } else {
      updatedStudents = updatedStudents;
    }

    setTableData(updatedStudents);
    setFilteredTableData({
      dataArray: updatedStudents,
    });
  };

  const handleGobalSearch = () => {
    if (globalSearchText === "") {
      setFilteredTableData({
        dataArray: tableData,
      });
    } else {
      let filteredData = tableData.filter((data) => {
        // const matchesTransactionNumbers = data.transactionNumbers
        const matchesTransactionNumbers = data.transactionDetails.transactionNo
          ? // ? data.transactionNumbers.some((tn) =>
            data.transactionDetails.transactionNo.some((tn) =>
              (tn?.toLowerCase() || "").includes(globalSearchText.toLowerCase())
            )
          : false;

        return (
          matchesTransactionNumbers ||
          data.firstName
            .toLowerCase()
            .includes(globalSearchText.toLowerCase()) ||
          data.lastName
            .toLowerCase()
            .includes(globalSearchText.toLowerCase()) ||
          data.enrollmentno
            .toLowerCase()
            .includes(globalSearchText.toLowerCase()) ||
          data.email.toLowerCase().includes(globalSearchText.toLowerCase()) ||
          (data.bdm1 &&
            data.bdm1.toLowerCase().includes(globalSearchText.toLowerCase())) ||
          (data.bdm2 &&
            data.bdm2.toLowerCase().includes(globalSearchText.toLowerCase()))
        );
      });
      setFilteredTableData({
        dataArray: filteredData,
      });
    }
  };

  // Updated FeeDetailsHeader with new columns
  const updatedFeeDetailsHeader = [
    ...FeeDetailsHeader,
    {
      key: "applicationFeeTransactionNo",
      label: "Application Fee Transaction No.",
    },
    { key: "tuitionFeeTransactionNo", label: "Tuition Fee Transaction No." },
    { key: "commissionTransaction", label: "Commission Transaction No." },
  ];

  function getTransactionNumbers(transactionDetails, feeType) {
    const transactions = transactionDetails.filter((t) => t.type === feeType);
    const transactionNumbers = transactions.map((t) => t.transactionNo);
    return transactionNumbers.join(", "); // Join with commas
  }

  // Function to extract transaction numbers by type
  function extractTransactionNumber(transactionDetails, feeType) {
    const transaction = transactionDetails.find((t) => t.type === feeType);
    return transaction ? transaction.transactionNo : ""; // Return empty if not found
  }

  // Preprocess the table data to add separate columns for Application and Tuition fees
  const processedTableData = tableData.map((row) => ({
    ...row,
    // applicationFeeTransactionNo: extractTransactionNumber(row.transactionDetails, 'Application_Fee'),
    applicationFeeTransactionNo: getTransactionNumbers(
      row.transactionDetails,
      "Application_Fee"
    ),
    tuitionFeeTransactionNo: getTransactionNumbers(
      row.transactionDetails,
      "Tution_Fee"
    ),
    commissionTransaction: getTransactionNumbers(
      row.transactionDetails,
      "Commission"
    ),
    // tuitionFeeTransactionNo: extractTransactionNumber(row.transactionDetails, 'Tution_Fee'),
  }));

  useEffect(() => {
    handleGobalSearch();
  }, [globalSearchText]);

  useEffect(() => {
    fetchDataFromBackend();
  }, [students]);
  useEffect(() => {
    getstudents();
    setStage(stageNo);
  }, []);

  return (
    <div className="m-5">
      <div className="flex justify-start items-center">
        <div>
          <input
            type="text"
            id="last_name"
            style={{
              minWidth: "300px",
            }}
            className="bg-gray-50 text-center my-2 mx-auto border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Search .."
            onChange={(e) => {
              setGlobalSearchText(e.target.value);
            }}
          />
        </div>
        <div>
          <CSVLink
            id="csv-link"
            // data={tableData}
            // data={processedTableData}
            // headers={FeeDetailsHeader}
            data={processedTableData} // Use the processed data with separate columns
            headers={updatedFeeDetailsHeader} // Use the updated header
            filename={"feeDetails.csv"}
          />
          <button
            className=" border-2 border-blue-500 mx-2 rounded-md text-blue-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            onClick={handleDownload}
          >
            Download CSV
          </button>
          <button
            className=" border-2 border-blue-500 mx-2 rounded-md text-blue-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            onClick={() => {
              setIsBdmReportOpen(true);
            }}
          >
            BDM REPORT
          </button>
        </div>
      </div>
      <Toaster />
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "blue", color: "white" },
        }}
        width={1700}
      >
        <Navbar setIsOpen={setIsModalVisible} />
      </Modal>
      <Modal
        open={isBdmReportOpen}
        onOk={handleBdmReportOk}
        onCancel={handleBdmReportCancel}
        okButtonProps={{
          style: { backgroundColor: "blue", color: "white" },
        }}
        width={1700}
      >
        <Report dataSource={bdmReport} />
      </Modal>
      <div className="mx-auto p-4">
        {loading !== true ? (
          <DynamicTable
            setIsModalVisible={setIsModalVisible}
            showModal={showModal}
            handleCancel={handleCancel}
            data={filteredTableData.dataArray}
          />
        ) : (
          <>
            <Spin />
          </>
        )}
      </div>
    </div>
  );
}

export default StudentTable;
