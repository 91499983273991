import React, { useEffect, useState, useContext } from "react";

import { BsSend } from "react-icons/bs";
import { MdOutlineDownloadDone } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

import { toast, Toaster } from "react-hot-toast";

import TransactionRow from "./TransactionRow";

import { sendMail, sendPaymentMail } from "../../../api/payment";
import { PaymentMail } from "../../../helper/paymentMail";

import { StudentContext } from "../../../context/StudentContext";
import ApplicationFeesRefund from "./ApplicationFeesRefund";
const Paymentcompo = () => {
  const [cal, setCal] = useState(0);

  const [status, setStatus] = useState("Pending");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [autoComment, setAutoComment] = useState(false);
  const studentContext = useContext(StudentContext);

  const {
    inEditStudent,
    setInEditStudent,
    paymentInfo,
    setPaymentInfo,
    refundPayment,
    setRefundPayment,
  } = studentContext;

  const [formState, setFormState] = useState({});
  const [applicationTransactions, setApplicationTransactions] = useState([]);

  const [role, setRole] = useState("");


  const addTransaction = () => {
    const calData = fetchCal();
    if (calData === 0) {
      toast.success("Application Fee Payment Already Done!!");
      return;
    }
    const newApplicationPayments = [
      ...paymentInfo.applicationTransactions,
      {
        amount: 0,
        ttCopyName: "",
        ttCopy: null,
        transactionNo: "",
        paymentStatus: "",
        id: null,
        type: "Application_Fee",
      },
    ];
    setPaymentInfo({
      ...paymentInfo,
      applicationTransactions: newApplicationPayments,
    });
  };

  const fetchCal = () => {
    let tAmount = 0;
    paymentInfo.applicationTransactions.forEach((transaction) => {
      tAmount += transaction.amount;
    });
    let calData = parseInt(inEditStudent.registrationFee) - parseInt(tAmount);

    setCal(calData);
    return calData;
  };

  const handleChangeTransaction = (transactionDetails, event, index) => {
    const { name, value, type, files } = event.target;
    const updatedTransaction = transactionDetails;
    const allTransaction = paymentInfo.applicationTransactions;
    if (type === "file") {
      const selectedFile = files[0];
      updatedTransaction["ttCopyName"] = selectedFile.name;
      updatedTransaction["ttCopy"] = selectedFile;
    } else {
      if (name === "amount") updatedTransaction[name] = parseInt(value);
      else updatedTransaction[name] = value;
    }
    allTransaction[index] = updatedTransaction;
    setPaymentInfo({
      ...paymentInfo,
      applicationTransactions: allTransaction,
    });
  };

  useEffect(() => {
    fetchCal();
  }, []);

  

  const [paymentLink, setPaymentLink] = useState("");
  const isAdmin = role === "admin";
  // const roleAC = isAdmin ? "Admin" : selectedCounselorLoop?.name;
  const paymentMail = async () => {
    const emailTemplate = PaymentMail(inEditStudent, paymentLink);

    const obj = {
      email: inEditStudent.email,
      subject:
        "Payment Instructions| Your Application at Lyon Institute for Geostrategy & Multicultural Relations - LIGMR - FRANCE",
      message: emailTemplate,
    };
    await sendMail(obj);
    // const Message = `Payment link [${paymentLink}] sent to student by Accountant.`;
    // await addCommentsInStage(Message);
    toast.promise(sendPaymentMail(inEditStudent.id), {
      loading: "Sending the Payment mail...",
      success: (value) => value.success,
      error: (value) => value.error,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const sendEmail = async () => {
    setStatus("Pending");
    if (!paymentLink) {
      toast.error("Please upload the payment Link");
      return;
    }
    paymentMail();
    setIsEmailSent(true);
  };

  useEffect(() => {
    if (
      inEditStudent?.paymentStatus === "Pending" ||
      inEditStudent?.paymentStatus === "Done"
    ) {
      setIsEmailSent(true);
      setStatus(inEditStudent?.paymentStatus);
    }
    if (inEditStudent?.paymentStatus === "Done") {
      setStatus("Done");
      setFormState({ ttCopyName: inEditStudent.ttCopyName });
    }
  }, []);
  return (
    <div className="container mx-auto h-full mt-8 w-full">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white p-8 rounded-lg max-h-4/5 mx-auto border-2 border-gray-300 shadow-lg transition duration-300">
        <div className="mt">
          <div className=" my-3 flex flex-row items-center">
            <div className="m-1">
              <input
                type="text"
                value={paymentLink}
                onChange={(e) => setPaymentLink(e.target.value)}
                placeholder="Payment Link"
                className="border rounded-md border-gray-300 p-1.5 w-full focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out transform hover:shadow-md"
              />
            </div>
            <div
              className="flex flex-row w-fit p-2 items-center border-2 rounded-md transition duration-300 hover:scale-105 hover:border-blue-500 cursor-pointer"
              onClick={sendEmail}
            >
              <span className="font-semibold text-blue-500 ">Send Mail</span>
              <BsSend className="text-xl m-1 text-blue-500 hover:scale-105" />
            </div>
            <div
              className={`flex flex-row w-fit items-center`}
              onClick={sendEmail}
            >
              {isEmailSent ? (
                <MdOutlineDownloadDone className="text-2xl m-1 text-green-500" />
              ) : (
                <IoMdCloseCircle className="text-2xl m-1 text-red-500" />
              )}
            </div>

            <div className="grid grid-cols-1 justify-center border-2 border-blue rounded-full p-2">
              <span className="flex items-center justify-center text-center text-blue font-semibold">
                Application Payment Remaining :{cal}
              </span>
            </div>
          </div>

          <div className="grid lg:grid-cols-1 items-center">
            <button
              onClick={addTransaction}
              className="px-8 py-3 ml-auto bg-blue-500 hover:bg-blue-600 text-white w-fit rounded my-2"
            >
              Add
            </button>
            <div className="overflow-x-auto">
              <table className="w-full rounded-xl bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="py-2 w-100 px-4 border-b border-l">Date</th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Add ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Amount (€)
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">Bank</th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Save ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      View ttCopy
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Transaction Number
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Save Transaction Info
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Payment Status
                    </th>
                    <th className="py-2 w-100 px-4 border-b border-l">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentInfo.applicationTransactions &&
                  paymentInfo.applicationTransactions.length > 0 ? (
                    paymentInfo.applicationTransactions.map(
                      (payment, index) => (
                        <TransactionRow
                          studentId={inEditStudent.id}
                          handleChangeTransaction={handleChangeTransaction}
                          payment={payment}
                          index={index}
                          fetchCal={fetchCal}
                        />
                      )
                    )
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {paymentInfo.applicationTransactions.length === 0 && (
                <div className="text-center py-4">
                  <p>No payment information available</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-end items-center">
            <p className="text-gray-800 text-2xl font-bold my-4">Refund</p>
          </div>
          <ApplicationFeesRefund />
        </div>
      </div>
    </div>
  );
};

export default Paymentcompo;
