import React, { useContext, useEffect, useState } from "react";
import { StudentContext } from "../../../context/StudentContext";
import toast, { Toaster } from "react-hot-toast";
import moment, { duration } from "moment";
import { studentUpdate } from "../../../api/student";

import { LiaUserEditSolid } from "react-icons/lia";
import { FaSave } from "react-icons/fa";

const StudentInformation = () => {
  const studentContext = useContext(StudentContext);
  const { students, inEditStudent, setInEditStudent } = studentContext;

  const [isFeeEditing, setIsFeeEditing] = useState(false);
  const [prevInEditStudent, setPrevInEditStudent] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let tutionFee = 0;
    let duration = inEditStudent.duration;
    if (name === "diploma") {
      if (value === "BBA") {
        tutionFee = 8500;
      } else if (value === "MBA") {
        tutionFee = 9500;
        duration = 1;
      } else {
        tutionFee = 0;
      }
    } else {
      if (inEditStudent.diploma === "BBA") {
        tutionFee = 8500;
      } else if (inEditStudent.diploma === "MBA") {
        tutionFee = 9500;
      } else {
        tutionFee = 0;
      }
    }

    if (name === "diploma") {
      // tutionFee *= parseInt(duration);

      if (value == "BBA") {
        setInEditStudent({
          ...inEditStudent,
          tutionFee,
          duration,
          [name]: value,
          ["mba"]: "",
          ["mbaDegree"]: "",
          ["bts"]: "",
        });
      }
      if (value == "MBA (15 Months)") {
        setInEditStudent({
          ...inEditStudent,
          [name]: value,
        });
      }
      if (value == "MBA") {
        setInEditStudent({
          ...inEditStudent,
          tutionFee,
          duration,
          [name]: value,
          ["bba"]: "",
          ["bbaDegree"]: "",
          ["bts"]: "",
        });
      }
      if (value == "BTS") {
        setInEditStudent({
          ...inEditStudent,
          tutionFee,
          duration,
          [name]: value,
          ["bba"]: "",
          ["bbaDegree"]: "",
          ["mba"]: "",
          ["mbaDegree"]: "",
        });
      }
      // setInEditStudent({
      //   ...inEditStudent,
      //   tutionFee,
      //   duration,
      //   [name]: value,
      // });
    } else if (name === "duration") {
      // tutionFee *= parseInt(value);
      setInEditStudent({
        ...inEditStudent,
        tutionFee,
        [name]: value,
      });
    } else {
      setInEditStudent((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFeeSaveClick = async () => {
    if (inEditStudent.registrationFee === "") {
      toast.error("Registration Fee");
      return;
    }
    if (inEditStudent.tutionFee === "") {
      toast.error("Registration Fee");
      return;
    }
    setIsFeeEditing(true);
    try {
      await toast.promise(
        studentUpdate(inEditStudent.id, {
          registrationFee: inEditStudent.registrationFee,
          tutionFee: inEditStudent.tutionFee,
          duration: inEditStudent.duration,
          scholarshipDiscount: inEditStudent.scholarshipDiscount,
          fullFee: inEditStudent.fullFee,
        }),
        {
          loading: "Updating Student information...",
          success: (value) => value.success,
          error: (value) => value.error,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
      setPrevInEditStudent(inEditStudent);
    } catch (error) {
      console.error("Error updating student information:", error);
    }
    setIsFeeEditing(false);
  };

  const handleFeeEditClick = () => {
    setIsFeeEditing(true);
  };

  useEffect(() => {
    setPrevInEditStudent(inEditStudent);
  }, []);

  let inputClassName =
    "px-4 py-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ml-2 w-full";
  let dataClassName =
    "px-4 py-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ml-2 bg-gray-100 w-full";
  return (
    <div className="">
      <div className="w-full">
        <div className="container mx-auto h-full mt-8 w-full">
          <div className="bg-white p-8 rounded-lg max-h-4/5 mx-auto border-2 border-gray-300">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="col-span-1">
                <label className="text-gray-600 flex">Enrollment No:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.enrollmentno}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Title:</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.title}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Last Name:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {" "}
                  {inEditStudent.lastName?.toUpperCase()}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">First Name:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.firstName.charAt(0).toUpperCase() +
                    inEditStudent.firstName.slice(1)}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Address:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.address}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Zip:</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.zip}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">City:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.city}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">State:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.state}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Country:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.country}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Date of Birth:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {moment(inEditStudent.dob).format("DD-MM-YYYY")}
                </p>
              </div>

              <div className="col-span-1">
                <label className="text-gray-600 flex">Phone:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.phoneNumber}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Email:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.email}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Current School:</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.currentSchool}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Current Degree:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.currentDegree}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Passport No:</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.passportNo}
                </p>
              </div>
              <div className="col-span-1">
                <label className="text-gray-600 flex">Collage :</label>

                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.college}
                </p>
              </div>
              {inEditStudent.knowFrom === "By a Member of staff" ? (
                <div className="col-span-1">
                  <label className="text-gray-600 flex">Staff Name:</label>
                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.staffMember}
                  </p>
                </div>
              ) : (
                <></>
              )}

              {inEditStudent.knowFrom === "Agency" ? (
                <div className="col-span-1">
                  <label className="text-gray-600 flex">Agency:</label>

                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.agency}
                  </p>
                </div>
              ) : (
                <></>
              )}

              {inEditStudent.knowFrom === "Agency" ? (
                <div className="col-span-1">
                  <label className="text-gray-600 flex">Agency Email:</label>

                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.agencyEmail}
                  </p>
                </div>
              ) : (
                <></>
              )}

              {/* {!isEditing && <label htmlFor="">Degree Type :</label>} */}

              <div className={`col-span-1`}>
                <label htmlFor="">Degree Type :</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.degreeType === "apprentice" ||
                  inEditStudent.degreeType === "short-program"
                    ? "SHORT PROGRAM"
                    : "FULL TIME"}
                </p>
              </div>

              <div className={`col-span-1`}>
                <label htmlFor="">InTake :</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.intake === "jun-2024"
                    ? "June 2024"
                    : "September 2024"}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 w-full mt-5 ml-2 gap-4 md:grid-cols-3">
              <div className="col-span-1">
                <label htmlFor="">Degree :</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.diploma}
                </p>
              </div>
            </div>

            <div className="flex items-center mt-8">
              <p className="text-gray-800 text-2xl font-bold mb-4">
                Conditional Information
              </p>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="space-x-2 ml-2"
              >
                {!isFeeEditing && (
                  <LiaUserEditSolid
                    onClick={handleFeeEditClick}
                    className={`cursor-pointer mb-2 border-2 p-1 border-blue-500 text-blue-500 rounded-full text-3xl `}
                  />
                )}
                {isFeeEditing && (
                  <FaSave
                    className={`cursor-pointer mb-2 border-2 p-1 border-blue-500 text-blue-500 rounded-full text-3xl`}
                    onClick={() => {
                      handleFeeSaveClick();
                      setIsFeeEditing(false);
                    }}
                  />
                )}
              </span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className={`col-span-1`}>
                <label htmlFor="">Duration :</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {inEditStudent.duration !== null
                    ? inEditStudent.duration
                    : "Not Select"}
                </p>
              </div>

              <div className="col-span-1">
                <label className="text-gray-600 flex">
                  Registration Fee (€):
                </label>

                {isFeeEditing ? (
                  <input
                    type="number"
                    name="registrationFee"
                    value={inEditStudent.registrationFee}
                    onChange={(e) => handleInputChange(e)}
                    className={inputClassName}
                    placeholder="€"
                  />
                ) : (
                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.registrationFee}
                  </p>
                )}
              </div>

              <div className="col-span-1">
                <label className="text-gray-600 flex">Tuition Fee (€):</label>

                {isFeeEditing ? (
                  <>
                    <input
                      type="number"
                      name="tutionFee"
                      value={inEditStudent.tutionFee}
                      onChange={(e) => handleInputChange(e)}
                      className={inputClassName}
                      placeholder="€"
                    />
                    {/* <p className={`font-semibold ${dataClassName}`}>
                      {inEditStudent.tutionFee}
                    </p> */}
                  </>
                ) : (
                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.tutionFee}
                  </p>
                )}
              </div>

              <div className="col-span-1">
                <label className="text-gray-600 flex">
                  Scholarship Discount (€):
                </label>

                {isFeeEditing ? (
                  <input
                    type="number"
                    name="scholarshipDiscount"
                    value={inEditStudent.scholarshipDiscount}
                    onChange={(e) => handleInputChange(e)}
                    className={inputClassName}
                    placeholder="€"
                  />
                ) : (
                  <p className={`font-semibold ${dataClassName}`}>
                    {inEditStudent.scholarshipDiscount}
                  </p>
                )}
              </div>

              <div className="col-span-1">
                <label className="text-gray-600 flex">Full fees (€):</label>
                <p className={`font-semibold ${dataClassName}`}>
                  {isNaN(
                    parseInt(inEditStudent.registrationFee) +
                      parseInt(inEditStudent.tutionFee) -
                      parseInt(inEditStudent.scholarshipDiscount)
                  )
                    ? 0
                    : parseInt(inEditStudent.registrationFee) +
                      parseInt(inEditStudent.tutionFee) -
                      parseInt(inEditStudent.scholarshipDiscount)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentInformation;
