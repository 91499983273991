import React, { useEffect, useState, useContext } from "react";

import { toast } from "react-hot-toast";

import { FaFilePdf } from "react-icons/fa6";
import { FaFileUpload } from "react-icons/fa";
import { CiSaveDown2 } from "react-icons/ci";
import { LoadingOutlined } from "@ant-design/icons";
import { FaRegEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { Spin } from "antd";

import { sendMail } from "../../../api/payment";
import { PaymentRecieve } from "../../../helper/paymentReceive";
import { decryptRole } from "../../../helper/EncryptionUtils";
import { addComment } from "../../../api/comment";
import { deletePayment } from "../../../api/student";
import {
  updatePaymentStatus1,
  addTransactionNo,
  getPDF,
  updateTransaction,
} from "../../../api/payment";

import { StudentContext } from "../../../context/StudentContext";
import { SentApprovalPayment } from "../../../Emailtemplate/SentApprovalPayment";

import moment from "moment";

const TransactionRow = ({
  payment,
  index,
  fetchCal,
  studentId,
  handleChangeTransaction,
}) => {
  const studentContext = useContext(StudentContext);
  const {
    inEditStudent,
    setInEditStudent,
    paymentInfo,
    fetchPaymentInfo,
    getstudents,
  } = studentContext;

  const [autoComment, setAutoComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Pending");
  const [role, setRole] = useState("");
  const [ttcopy, setTtcopy] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [fileEditMode, setFileEditMode] = useState(false);

  const [formState, setFormState] = useState({
    amount: 0,
    ttCopyName: "",
    ttCopy: null,
    transactionNo: "",
    paymentStatus: "",
    type: "Tution_Fee",
    bank: "",
  });
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleChangeTransaction(formState, e, index);
    setFormState({
      ...formState,
      ttCopy: selectedFile,
      ttCopyName: selectedFile.name, // Store the file name
    });
  };
  const openPdf = async () => {
    if (formState.ttCopyName === "") {
      toast.error("Please upload the PDF first");
      return;
    }
    setLoading(true);
    const data = await getPDF({
      // encryptedUrl: payment[`ttCopyName`],
      url: `uploads/documents/${inEditStudent.email}/ttCopy${formState.type}${index}.pdf`,
    });
    if (data.success) {
      window.open(data.url, "_blank");
    } else {
      toast.error(data.msg);
    }
    setLoading(false);
  };
  const addCommentsInStage = async (commentMessage) => {
    const data = await addComment(inEditStudent.id, 6, {
      comment: commentMessage.trim(),
    });
    setAutoComment(true);
    setTimeout(() => {
      setAutoComment(false);
    }, 1000);
  };

  const updatePaymentTransaction = async (id, formData) => {
    let responseData;
    // if payment already done then update it
    if (payment.id) {
      formData.append("transactionId", payment.id);
      await toast.promise(updateTransaction(id, formData), {
        loading: "Updating payment status...", // Displayed while the promise is resolving
        success: (data) => {
          // Displayed when the promise resolves successfully
          responseData = data.data;
          return data.success;
        },
        error: (data) => {
          // Displayed when the promise is rejected
          return data.error;
        },
      });
    } else {
      await toast.promise(updatePaymentStatus1(id, formData), {
        loading: "Updating payment status...", // Displayed while the promise is resolving
        success: (data) => {
          // Displayed when the promise resolves successfully
          responseData = data.data;
          return data.success;
        },
        error: (data) => {
          // Displayed when the promise is rejected
          return data.error;
        },
      });
    }
    return responseData;
  };

  const saveTTCopy = async () => {
    try {
      if (!formState.ttCopyName) {
        toast.error("Please select a PDF file");
        return;
      }
      if (formState.amount === "") {
        toast.error("Please enter the amount");
        return;
      }
      if (formState.bank === "") {
        toast.error("Please select bank");
        return;
      }
      const paymentToPay = fetchCal();
      if (paymentToPay < 0) {
        toast.error("amount added is more than the amount to pay !");
        return;
      }

      const formData = new FormData();
      formData.append("email", inEditStudent.email);
      formData.append("index", index);
      formData.append("type", formState.type);
      formData.append("amount", formState.amount);
      formData.append("ttCopy", formState.ttCopy);
      formData.append("bank", formState.bank);

      let responseData = await updatePaymentTransaction(
        inEditStudent.id,
        formData
      );

      // const data = await updatePaymentStatus1(inEditStudent.id, formData);
      setFormState({
        ttCopyName: responseData.ttCopyFileUrl,
        paymentStatus: "Sent For Approval",
      });
      if (responseData?.success) {
        toast.success(responseData?.msg);
        setFileEditMode(false);
        await fetchPaymentInfo();
        // send the mail to admin !
        formState.ttCopyName = "";
        formState.amount = "";

        await sendMail({
          email: "account@egniol.co.in",
          email: "mirajsoliya90@gmail.com",
          subject: `Payment Approval for Student Enrollment: ${inEditStudent.enrollmentno}`,
          message: SentApprovalPayment(inEditStudent, formState.amount),
          bcc: "admissions@ligmr.fr",
        });
        // update the student Data .
        setInEditStudent({
          ...inEditStudent,
          paymentStatus: "Sent for approval",
          ttCopyName: responseData.ttCopyFileUrl,
        });
        const Message = `saveTTCopy Successfully and sent mail to student by Accountant!!`;
        await addCommentsInStage(Message);
        setStatus("Done");
        setTtcopy(true);
      } else {
        toast.error("Failed to save PDF");
      }
    } catch (error) {
      console.error("Error saving PDF:", error);
    }
  };
  const paymentDoneMail = async () => {
    const emailTemplate = PaymentRecieve(inEditStudent);
    const obj = {
      email: inEditStudent.email,
      subject:
        "Payment Recieved| Your Application to Lyon Institute for Geostrategy & Multicultural Relations - LIGMR - FRANCE",
      message: emailTemplate,
    };
    let data = await sendMail(obj);
    // data = await sendPaymentMail(inEditStudent.id);
    if (data.success) {
      toast.success(data.message);
    } else {
      toast.error(data.error);
    }
  };
  const handleTransactionNo = async () => {
    if (formState.transactionNo === null) {
      toast.error("Enter the transaction no");
      return;
    }
    if (formState.paymentStatus === "Done") {
      toast.error("Payment is already Approved for this transaction !");
      return;
    }

    const data = await addTransactionNo(inEditStudent.id, payment.id, {
      transactionNo: formState.transactionNo,
    });

    if (data?.success) {
      let msg = window.confirm("Do you want to send the mail to student !!");
      if (msg) {
        await paymentDoneMail();
      }

      await fetchPaymentInfo();
      await getstudents();
      toast.success(data?.msg);
      const Message = `Payment of transactionNo ${formState.transactionNo} Approved by Accounter!!`;
      await addCommentsInStage(Message);
      // update the student Data .
      setInEditStudent({
        ...inEditStudent,
        paymentStatus: "Done",
        ttCopyName: formState.ttCopyName,
        transactionNo: formState.transactionNo,
      });
      // await getDataStageWise(stage);
    } else {
      toast.error("Failed to add transaction Number");
    }
  };

  const handlePaymentDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this payment?"
    );
    if (confirmed) {
      await toast.promise(deletePayment(studentId, payment.id), {
        loading: "Deleting Payment ...",
        success: (data) => {
          return data.msg;
        },
        error: (data) => {
          return data.error;
        },
      });
      const commentMessage = `${payment.amount}€ transaction for Tution fees Deleted by Account Department`;
      await addCommentsInStage(commentMessage);
      await fetchPaymentInfo();
    }
  };

  useEffect(() => {
    setFormState({
      ...formState,
      amount: payment.amount,
      transactionNo: payment.transactionNo,
      paymentStatus: payment.paymentStatus,
      type: payment.type,
      bank: payment.bank,
    });
    fetchCal();
  }, [paymentInfo]);

  // Formating the Date to Dd/MM/YYYY
  function formatDate(sqlDateTime) {
    return moment(sqlDateTime).format("DD/MM/YYYY");
  }

  useEffect(() => {
    const fetchRole = async () => {
      const roleData = localStorage.getItem("role");
      if (roleData) {
        const decryptedRole = decryptRole(roleData);
        setRole(decryptedRole);
      }
    };

    fetchRole();
    setFormState({
      ...payment,
      createdAt: payment.createdAt ? formatDate(payment.createdAt) : "",
    });
  }, [payment]);

  const handleTransactionChanged = async (e) => {
    const { name, value } = e.target;
    handleChangeTransaction(formState, e, index);
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <tr key={index}>
      <>
        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="flex flex-col items-center ">
            {payment?.createdAt && formState.createdAt}
          </div>
        </td>
        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="flex flex-col items-center ">
            <div className=" flex  space-x-4 justify-center">
              <span className="cursor-pointer  text-center border-b-2 border-blue-500 text-black font-semibold hover:bg-primary-dark focus:outline-none focus:ring focus:border-primary">
                {formState.ttCopyName ? "Uploaded" : "Now Uploaded"}
              </span>
              <input
                type="file"
                accept=".pdf"
                name={`ttCopy${index}`}
                className="hidden"
                onChange={handleFileChange}
              />
              <button
                type="button"
                className={`bg-white-500 rounded-md px-3 font-semibold hover:bg-primary-dark focus:border-primary`}
                disabled={!fileEditMode}
                onClick={() => {
                  document.getElementsByName(`ttCopy${index}`)[0].click();
                }}
              >
                <FaFileUpload
                  className={
                    formState.ttCopyName ? "text-green-500" : "text-red-500" // Change color based on file selection
                  }
                />
              </button>
              {inEditMode && (
                <div className="flex space-x-4">
                  {!fileEditMode && (
                    <button
                      className="text-white flex items-center p-1 rounded space-x-2 bg-blue-500"
                      disabled={!inEditMode}
                      onClick={() => {
                        setFileEditMode(true);
                        setFormState({
                          ...formState,
                          ttCopyName: "",
                          // ttCopy: null,
                        });
                      }}
                    >
                      <p>Edit</p>
                      <FaRegEdit />
                    </button>
                  )}

                  {fileEditMode && (
                    <button
                      className="text-white p-1 rounded items-center bg-red-500 flex space-x-2"
                      // disabled={!inEditMode}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          ttCopyName: payment.ttCopyName,
                          // ttCopy: null,
                        });
                        setFileEditMode(false);
                      }}
                    >
                      <p>Discard</p>
                      <MdCancel />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="flex flex-col items-center ">
            <div className="grid grid-cols-1 justify-center">
              <input
                type="number"
                name="amount"
                placeholder="Amount."
                readOnly={!inEditMode}
                value={formState.amount}
                onChange={(e) => handleTransactionChanged(e)}
                className="border rounded-md border-gray-300 p-1.5 w-full focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out transform hover:shadow-md"
              />
            </div>
          </div>
        </td>
        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="flex flex-col items-center">
            <div className="grid grid-cols-1 justify-center">
              <select
                name="bank"
                placeholder="Bank"
                readOnly={formState.paymentStatus === "Done"}
                value={formState.bank}
                onChange={(e) => handleTransactionChanged(e)}
                className="border rounded-md border-gray-300 p-1.5 w-full focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out transform hover:shadow-md"
              >
                <option value="">Select Bank</option>
                <option value="LIGMR FRANCE - BNP PARIBAS">
                  LIGMR FRANCE - BNP PARIBAS
                </option>
                <option value="LIGMR STRIPE">LIGMR STRIPE</option>
                <option value="LIGMR RAZORPAY">LIGMR RAZORPAY</option>
                <option value="QONTO - LIGMR">QONTO - LIGMR</option>
                <option value="LIGMR KOTAK">LIGMR KOTAK</option>
                <option value="STRIPE - ANKIT SONI A/C">
                  STRIPE - ANKIT SONI A/C
                </option>
                <option value="ESPL RAZORPAY">ESPL RAZORPAY</option>
                <option value="ESPL ICICI">ESPL ICICI</option>
                <option value="CASH">CASH</option>
                <option value="STRIPE - LIGMR">STRIPE - LIGMR</option>
                <option value="LIGMR - ANKIT SONI A/C">
                  LIGMR - ANKIT SONI A/C
                </option>
                <option value="DELUBAC BANK">DELUBAC BANK</option>
              </select>
            </div>
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l ">
          <div className="flex justify-center flex-row items-center cursor-pointer">
            <button
              type="button"
              // readOnly={formState.ttCopyName ? true : false}
              disabled={!inEditMode}
              onClick={async () => {
                // if (!payment.transactionNo) {
                await saveTTCopy();
                // } else {
                // toast.error(
                // "Payment has already Created , it can not be changed"
                // );
                // }
              }}
              className={`flex justify-center flex-row items-center cursor-pointer mx-auto bg-white-500 rounded-md font-semibold hover:bg-primary-dark focus:border-primary`}
            >
              <span className="font-semibold m-3">Save</span>
              <CiSaveDown2 className="text-blue-500  cursor-pointer hover:scale-105  text-xl font-semibold" />
            </button>
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="flex space-x-4 items-center justify-center">
            <button
              type="button"
              className={`bg-white-500 flex items-center rounded-md font-semibold hover:bg-primary-dark focus:border-primary`}
              onClick={() => {
                openPdf();
              }}
              disabled={loading}
            >
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : payment.id ? (
                <FaFilePdf className="text-green-500 text-xl " />
              ) : (
                <FaFileUpload className="text-red-500 text-xl" />
              )}
            </button>
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="px-2">
            <input
              type="text"
              name="transactionNo"
              placeholder="Transaction No."
              value={formState.transactionNo}
              // readOnly={formState.transactionNo ? true : false}
              readOnly={!inEditMode}
              onChange={handleTransactionChanged}
              className="border  rounded-md border-gray-300 p-1.5 w-full focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out transform hover:shadow-md"
            />
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l ">
          <div
            className=""
            // onClick={handleTransactionNo(payment.id)}
            // onClick={handleTransactionNo}
          >
            <button
              type="button"
              onClick={() => handleTransactionNo(payment.id)}
              disabled={!inEditMode}
              className={`flex justify-center flex-row mx-auto items-center cursor-pointer bg-white-500 rounded-md font-semibold hover:bg-primary-dark focus:border-primary`}
            >
              <span className="font-semibold m-3">Save</span>
              <CiSaveDown2 className="text-blue-500  cursor-pointer hover:scale-105  text-xl font-semibold" />
            </button>
          </div>
        </td>

        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="px-2 text-center">
            <p className="font-semibold">{formState.paymentStatus}</p>
          </div>
        </td>
        <td className="py-2 w-100 px-4 border-b border-l">
          <div className="px-2 text-center">
            {!inEditMode ? (
              <button
                className="font-semibold text-blue-500 cursor-pointer "
                onClick={() => setInEditMode(true)}
              >
                Edit
              </button>
            ) : (
              <div className="space-x-2 flex">
                <button
                  onClick={() => handlePaymentDelete()}
                  className="font-semibold bg-red-100 p-2 rounded-lg text-red-500 cursor-pointer "
                >
                  Delete
                </button>
                <button
                  onClick={() => setInEditMode(false)}
                  className="font-semibold bg-orange-100 p-2 rounded-lg text-orange-500 cursor-pointer "
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </td>
      </>
    </tr>
  );
};

export default TransactionRow;
